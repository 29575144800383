import { NgModule } from '@angular/core';
import { DialogInfoModule } from '@fundo/app/@ui/dialogs/dialog-info/dialog-info.module';

import { NotificationService } from './services/notification.service';

@NgModule({
  imports: [DialogInfoModule],
  providers: [NotificationService],
})
export class DialogNotificationModule {}
